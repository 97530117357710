<template>
  <div :id="`change-password-modal-${forced}`" uk-modal bg-close="false" esc-close="false" class="uk-modal-container uk-padding-large">
    <div class="uk-modal-dialog restrict-max-width">
      <form class="uk-form-horizontal" id="change-password" @submit.prevent="changePassword()">
          <br>
          
      <div class="uk-modal-header">
        <h2 v-if="forced" class="uk-modal-title uk-text-left">{{ $t( 'Pages.UserProfile.MandatoryPasswordReset' ) }}</h2>
        <h2 v-else class="uk-modal-title uk-text-left">{{ $t( 'Pages.UserProfile.ChangePassword' ) }}</h2>
      </div>
      <div class="uk-modal-body">
          <div class="uk-margin-small">
            
            <div class="uk-margin">
                <label class="uk-form-label uk-text-left">{{$t('Generic.Labels.NewPassword')}}</label>
                <div class="uk-form-controls">
                  <div class="uk-inline ">
                    <input class="uk-input uk-form-width-large" 
                     :class="{ 'invalid-border': errors.newPassword }"
                     :maxlength=46
                     :placeholder="$t('Generic.Labels.NewPassword')" 
                     v-model="newPassword"
                     name="hidden" :type="showPassword ? 'text' : 'password'"
                    @keyup="keymonitor($event)"
                    @blur="errors.newPassword = false"
                    @focus="errors.newPassword = false">
                    <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.newPassword" uk-icon="icon: warning"></span>
                    <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.newPassword" > {{errors.newPassword}}</span>
                    <a @click="showOrHidePassword" href="#" v-if="!errors.newPassword && newPassword" class="uk-form-icon uk-form-icon-flip">
                      <img  v-if="!showPassword" src="@/assets/hide password_icon.svg" alt="hide" uk-svg style="height:22px; color: var(--text-color);"/>
                      <img v-if="showPassword" src="@/assets/show password_icon.svg" alt="show" uk-svg  style="height:22px; color: var(--text-color);"/>
                    </a>
                  </div>
                </div>
            </div>

            <div class="uk-margin uk-margin-medium-top">
                <label class="uk-form-label uk-text-left">{{$t('Generic.Labels.ConfirmPassword')}}</label>
                <div class="uk-form-controls">
                  <div class="uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.confirmPassword" uk-icon="icon: warning"></span>
                    <input class="uk-input uk-form-width-large" 
                      :class="{ 'invalid-border': errors.confirmPassword }"
                      :maxlength=46 
                      :type="showConfirmPassword ? 'text' : 'password'" 
                      @keyup="keymonitor($event)"
                      @blur="errors.confirmPassword = false"
                      @focus="errors.confirmPassword = false" 
                      :placeholder="$t('Generic.Labels.ConfirmPassword')" 
                      v-model="confirmPassword" >
                    <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.confirmPassword" > {{ errors.confirmPassword }}</span>
                     <a @click="showOrHideConfirmPassword" href="#" v-if="confirmPassword && !errors.confirmPassword" class="uk-form-icon uk-form-icon-flip">
                      <img  v-if="!showConfirmPassword" src="@/assets/hide password_icon.svg" alt="hide" uk-svg style="height:22px; color: var(--text-color);"/>
                      <img v-if="showConfirmPassword" src="@/assets/show password_icon.svg" alt="show" uk-svg  style="height:22px; color: var(--text-color);"/>
                    </a>
                  </div>
                </div>
            </div>

          </div>
      </div>
      <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" v-if="!forced" @click.prevent="setDefaultFormData()" type="button">{{ $t('Actions.Cancel') }}</button>
          <button v-if="!showItems" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
          <input v-else type="submit" class="uk-button uk-button-primary" @click.prevent="changePassword();" :value="$t('Actions.Save')">
      </div>
      </form>

    </div>

  </div>
</template>

<script>
import Notification from '@/components/util/Notification';

export default {
    name: 'ChangePasswordModal',
    props: {
      forced: {
        type: Boolean,
        default: false,
      }
    },
    data () {
      return {
        showPassword: false,
        showConfirmPassword: false,
        newPassword: "",
        confirmPassword: "",
        showItems: true,
        form: {
          newPassword: "",
          confirmPassword: ""
        },
        errors: {
          newPassword: false,
          confirmPassword: false
      }
      }
    },
    beforeMount () {
      this.setDefaultFormData();
    },
    methods: {
      showOrHidePassword () {
        this.showPassword=!this.showPassword;
      },
      showOrHideConfirmPassword () {
        this.showConfirmPassword=!this.showConfirmPassword;
      },
      keymonitor (event) {
        if(event.key !== "Enter" )
        {
          this.errors.newPassword = false;
          this.errors.confirmPassword = false;
        }
    },
      async changePassword () {
        if (!this.validatePasswords()) return false
        else {
          this.showItems = false
          await this.$store.dispatch('updateUserPassword', { password: this.confirmPassword });
          this.showItems=true
          Notification.showNotification( this.$t('Generic.Messages.Successful'), this.$t('Pages.UserProfile.PasswordUpdated'));
          window.UIkit.modal( '#change-password-modal-' + this.forced  ).hide();
          this.setDefaultFormData();
          this.$nextTick(() => {
            this.$emit('changed', true);
          })
          return true;
        }
      },
      validatePasswords () {
      if (!this.newPassword || this.newPassword?.length < 10) {
        this.errors.newPassword = this.$t('Generic.Errors.Min10Chars');
        return false
      }
      else if (this.confirmPassword.length === 0) {
        this.errors.confirmPassword = this.$t('Generic.Errors.Min10Chars');
        return false
      } else if (this.newPassword !== this.confirmPassword) {
        this.errors.confirmPassword = this.$t('Generic.Errors.PasswordsShouldMatch')
        return false
      }
      return true
    },
      setDefaultFormData () {
        this.confirmPassword = '';
        this.newPassword = '';
        this.errors.newPassword = false;
        this.errors.confirmPassword = false;
        this.showPassword = false;
        this.showConfirmPassword = false;
      }
    }
}
</script>

<style scoped>
  .restrict-max-width {
    max-width: 720px !important;
    min-width: 400px;
  }
  .invalid-icon {
      color: #B94A48;
  }
  .show-front {
    position: relative;
    z-index: 1;
  }

  .eye-icon {
    margin: 2px;
    height: 36px;
    border: 0px;
  }
</style>
