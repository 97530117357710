<template>
  <div id="login" class="uk-height-viewport">
    <div class="uk-flex uk-flex-center">
      <div  style="width:480px">
        <div style="height:200px">
          <img class="uk-margin-large-top" src="@/assets/IE-Logo.png" height="220" width="448" alt="CMS3" />
        </div>
        <div
          v-if="!loading"
          class=" uk-card-body uk-box-shadow-xlarge custom-card-bg "
        >
          <div class="uk-margin uk-margin-medium-bottom">
          <h3 class="uk-text-lead">{{$t("Pages.Login.CMS3Login")}}</h3></div>
          <form id="login-form" @submit.prevent="onSubmit();" @reset.prevent="onReset();"> 
            <div class="uk-margin">
              <div class="uk-inline">
                <span class="uk-form-icon" uk-icon="icon: user"></span>
                <input
                  class="uk-input uk-form-width-large"
                  :class="{ 'invalid-border': errors.email }"
                  type="text"
                  v-model="form.email"
                  name="email"
                  id="email"
                  :placeholder="$t('Generic.Labels.Email')"
                  @keyup="keymonitor"
                  @blur="errors.email = false"
                  @focus="errors.email = false"
                />
                <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.email" uk-icon="icon: warning"></span>
                <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.email" >  {{errors.email}}</span>
              </div>
            </div>
            <div class="uk-margin">
              <div class="uk-inline password">
                <span class="uk-form-icon" uk-icon="icon: lock"></span>
                <input
                  class="uk-input uk-form-width-large"
                  :class="{ 'invalid-border': errors.password }"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="form.password"
                  name="password"
                  id="password"
                  :placeholder="$t('Generic.Labels.Password')"
                  @keyup="keymonitor($event)"
                  @blur="errors.password = false"
                  @focus="errors.password = false"
                />
                <span class="uk-form-icon uk-form-icon-flip invalid-icon  uk-margin-small-left" v-if="errors.password" uk-icon="icon: warning"></span>
                <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.password" >{{errors.password}}</span>
          
                <a @click="showOrHidePassword" href="#" v-if="!errors.password && form.password" class="uk-form-icon uk-form-icon-flip">
                    <img  v-if="!showPassword" src="@/assets/hide password_icon.svg" alt="hide" uk-svg style="height:22px; color: #999;"/>
                    <img v-if="showPassword" src="@/assets/show password_icon.svg" alt="show" uk-svg  style="height:22px; color: #999;"/>
                </a>
              </div>
            </div>
            <div class="uk-margin">
              <p v-if="timerInfo.length > 0" class="uk-text-danger">{{ $t( 'Pages.Login.FailedLoginAttempts', { totalFailedAttempts: totalFailedAuthAttempts, timerInfo: timerInfo } ) }}</p>
              <div class=" uk-margin-small uk-width-1-1 uk-height-1-1 uk-flex uk-flex-between">
                <span class="uk-text-left uk-width-1-2@s uk-margin-small-top">
                  <a class="uk-text-left" href="#forgot-password-modal" uk-toggle>{{$t('Pages.Login.ForgotPassword')}}</a>
                </span>
                <input
                  :class="timerInfo.length > 0 ? 'uk-button uk-button-default' : 'uk-button uk-button-primary'"
                  :disabled="timerInfo.length > 0 ? true : false"
                  type="submit"
                  :value="$t('Pages.Login.LogIn')"
                  @click.prevent="onSubmit();" />
              </div>
              <br>
              <div>
                <p>{{$t('Pages.Login.NewHere')}} <router-link :to="{ name: 'SignUp'}">
                {{$t('Pages.Login.SignUp')}} </router-link> </p>
              </div>
             </div>
          </form>
        </div>
        <div v-else class="uk-card uk-card-secondary uk-card-body">
          <h4 class="uk-text">{{$t('Actions.Processing')}}</h4>
          <span uk-spinner></span>
        </div>
      </div>
    </div>
  <div id="forgot-password-modal" uk-modal="bg-close: false; esc-close: false;" class="">

    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">

        <p></p>
          
          <div class="uk-margin">

            <p class="uk-text-lead">{{$t('Pages.Login.ForgotYourPassword')}}</p>
            <p class="uk-text-meta">{{$t('Pages.Login.NotToWorry')}}</p>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <input 
                  class="uk-input" 
                  id="form-horizontal-text"
                  :class="{ 'invalid-border': errors.passwordResetEmail }"
                  type="email" 
                  :placeholder="$t('Pages.Login.RegisteredEmail')" 
                  v-model="form.passwordResetEmail" 
                  autocomplete
                  @focus="passwordResetEmailFocused = false"
                  @keyup="validateEmail()"
                  @keydown.enter.exact.prevent="sendPasswordResetLink()"
                  @blur="validateEmailOnBlur()"
                  >
                <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.passwordResetEmail" uk-icon="icon: warning"></span>
                <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.passwordResetEmail" >{{errors.passwordResetEmail}}</span>
              </div>
            </div>
          </div>
          <button class="uk-button uk-button-default uk-modal-close uk-margin-right" tabindex="-1" @click.prevent="">{{ $t('Actions.Cancel') }}</button>
          <button v-if="!isLoading" class="uk-button uk-button-primary" @click.prevent="sendPasswordResetLink()">{{$t("Pages.Login.ResetLink")}}</button>
          <button v-else class="uk-button uk-button-primary uk-width-small" type="button" :disabled="isLoading"><div uk-spinner="ratio: 0.5"></div></button>  

    </div>

  </div>
  <ChangePasswordModal :forced="true" @changed="passwordChanged()" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Notification from '@/components/util/Notification';
import ChangePasswordModal from '@/components/modals/ChangePasswordModal'
import moment from 'moment';
import { isLoggedIn } from '@/middleware/auth'
import { sec } from '@/app.config.js'

export default {
  name: 'LoginView',
  components: {
    ChangePasswordModal
  },
  computed: {
    ...mapGetters([
      'organizations'
    ]),
    ...mapState({
      authLockout: state => state.venom.authLockout ? state.venom.authLockout : {}
    }),
    totalFailedAuthAttempts() {
      const attempts = this.authLockout.attempts || 0;
      return attempts;
    },
    lockoutUntill() {
      const time = this.authLockout.time || 'notset';
      return time;
    }
  },
  data() {
    return {
      isLoading: false,
      passwordResetEmailFocused:false,
      form: {
        email: "",
        password: "",
        passwordResetEmail: "",
      },
      errors: {
        email: false,
        password: false,
        passwordResetEmail: false
      },
      loading: false,
      error: null,
      timerInfo: '',
      showPassword: false
    };
  },
  methods: {
    showOrHidePassword () {
      this.showPassword=!this.showPassword;
    },
    keymonitor (event) {
        if(event.key !== "Enter" )
       {
         this.errors.password = false;
         this.errors.email = false;
       }
    },
    isValidEmail() {
      const email = this.form.email.trim();
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(email).toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    passwordChanged () {
      this.navigateOnLogin()
      setTimeout(() => {
        const modalElement = document.getElementById('change-password-modal-true')
        if (modalElement) {
          window.UIkit.modal('#change-password-modal-true').$destroy(true);
        }
      }, 500)
    },
    isValidForm() {
      const password = this.form.password.trim();
      if ( !this.isValidEmail() || !this.form.email ) {
        this.errors.email = this.$t('Generic.Errors.InvalidEmail');
      } else {
        this.errors.email = false;
      }
      if ( password.length <= 9 ) {
        this.errors.password = this.$t('Generic.Errors.Min10Chars');
      } else {
        this.errors.password = false;
      }
      if ( this.errors.email || this.errors.password ) {
        return false;
      } else {
        return true;
      }
    },
    validateEmail () {
      if (!this.form.passwordResetEmail.length) {
        if (this.errors.passwordResetEmail)
          return false
        return true
      }
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(this.form.passwordResetEmail).toLowerCase())) {
        this.errors.passwordResetEmail = false
        return true
      } else {
          this.errors.passwordResetEmail = this.$t('Generic.Errors.InvalidEmail')
        
      }
    },
    validateEmailOnBlur () {
      this.passwordResetEmailFocused= false
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.form.passwordResetEmail.length) {
        return true
      } else if (!re.test(String(this.form.passwordResetEmail).toLowerCase())) {
        this.errors.passwordResetEmail = this.$t('Generic.Errors.InvalidEmail')
      }
    },
    async sendPasswordResetLink () {
      if (this.errors.passwordResetEmail) return
      if (!this.form.passwordResetEmail.length) {
        this.errors.passwordResetEmail = this.$t('Generic.Errors.MandatoryField')
        return
      }
      this.isLoading = true
      const response = await this.$store.dispatch('requestPasswordReset', this.form.passwordResetEmail)
      this.isLoading = false
      if (response?.requestResetCMSUserPassword?.potentialEmail) {
        Notification.showNotification(this.$t('Pages.Login.PasswordResetSubmitted'), this.$t('Pages.Login.PasswordResetSubmittedDesc'))
        window.UIkit.modal('#forgot-password-modal').hide();
      } else {
        Notification.showNotification(this.$t('Generic.Messages.UnSuccessful'), this.$t('Pages.InviteUser.errors.UserMustHaveCMSAccount'), 'error')
      }
    },
    async onSubmit() {
      try {
        if ( !this.isValidForm() ) {
          return '';
        }
        this.error = null;
        this.loading = true;
        const loginStatus = await this.$store.dispatch("loginUser", {
          email: this.form.email,
          password: this.form.password
        });
        if (!loginStatus.status) {
          this.setFailedAttempt();
          this.error = loginStatus?.error || loginStatus;
          this.loading = false;
          Notification.showNotification( this.$t('Pages.Login.AuthFailed'), this.error, 'error');
        } else {
          // reset lockout status (if any) when successfully loggedin
          this.$store.commit( 'setLockoutTime', { attempts: 0, time: 'notime' } );
          this.navigateOnLogin();
          return;

          // commeting out this code for future use if necessary

          // const { lastPasswordChange, registered } = loginStatus.response?.authenticateCMSUser?.user;
          // if ( !lastPasswordChange ){
          //   if ((moment().utc().diff(moment(registered), 'hours') < sec.passwordChangeFrequency * 24) ) {
          //     this.navigateOnLogin();
          //     return;
          //   }
          // }
          // if ((moment().utc().diff(moment(lastPasswordChange), 'hours') < sec.passwordChangeFrequency * 24)) {
          //   this.navigateOnLogin()
          //   return;
          // } else {
          //   window.UIkit.modal('#change-password-modal-true').show()
          // }
        }
      } catch (err) {
        this.loading = false;
        this.form.password = "";
        this.error = err.message.replace("Error:", "");
      }
    },
    navigateOnLogin() {
      // Redirect or go to Organizations page
      const { redirect = '' } = this.$route.params;
      if ( redirect && !redirect.includes('restricted')) {
        this.$router.push({ path: redirect });
      } else if (this.organizations && this.organizations.length === 1) {
        this.$router.push({ name: "Dashboard", params: { id: this.organizations[0].id }})
      } else {
        this.$router.push({ name: "Organizations" });
      }
    },
    onReset() {
      this.form.email= "";
      this.form.password = "";
    },
    setFailedAttempt() {
      const data = {
        attempts: this.totalFailedAuthAttempts + 1,
        time: 'notime'
      };
      this.$store.commit( 'setLockoutTime', data );
      this.startLockoutSession( data.attempts );
    },
    startLockoutSession( attempts ) {
      if ( attempts === 3 ) {
        this.lockoutTime( 1 );
      } else if ( attempts === 5 ) {
        this.lockoutTime( 3 );
      } else if ( attempts >= 7 ) {
        this.lockoutTime( 10 );
      }
    },
    lockoutTime( time ) {
      const currentTime = moment();
      const eventTime = moment().add( time, 'minutes' );
      const duration = moment.duration((eventTime - currentTime) / 1000, 'seconds');
      const interval = 1000;
      this.setTimerInfo( duration, interval );
    },
    setTimerInfo( duration, interval ) {
      setTimeout(() => {
        if (duration.asSeconds() <= 0) {
          if ( this.totalFailedAuthAttempts >= 7 ) {
            this.$store.commit( 'setLockoutTime', { attempts: 0, time: 'notime' } );
          }
        } else {
          duration = moment.duration(duration.asSeconds() - 1, 'seconds');
          if ( duration.minutes() === 0 && duration.seconds() === 0 ) {
            this.timerInfo = '';
          } else {
            this.timerInfo = ((duration.minutes() > 0) ? duration.minutes()+ 'm:' : '') + duration.seconds() + 's';
          }
          this.setTimerInfo( duration, interval );
        }
      }, interval);
    }
  },
  async created () {
    if (isLoggedIn() && sec.autoLogin) {
        this.loading = true
        const viewer = await this.$store.dispatch('getViewer')

        // Redirect or go to Organizations page
        const { redirect = '' } = this.$route.params;
        if ( redirect && !redirect.includes('restricted')) {
          this.$router.push({ path: redirect });
        } else if (viewer?.organizations && viewer?.organizations.length === 1) {
          this.$router.push({ name: "Dashboard", params: { id: viewer.organizations[0].id }})
        } else {
          this.$router.push({ name: "Organizations" });
        }
    } else {
      this.$store.dispatch("logoutUser");
    }
  },
  mounted() {
    // Clear all potential user data
    window.UIkit.util.on(document, 'beforeshow', '#forgot-password-modal', () => {
      this.form.passwordResetEmail = '';
      this.errors.passwordResetEmail = false
    })

    // lockout for previous failed attempts (if any)
    if ( this.lockoutUntill === 'notset' ) {
      this.$store.commit( 'setLockoutTime', { attempts: 0, time: 'notime' } );
    }
    this.startLockoutSession( this.totalFailedAuthAttempts );
  }
}
</script>

<style lang="scss" scoped>
h1 {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--app-primary-color);
}
.custom-card-bg {
  background: var(--app-secondary-color);
}
.uk-card-secondary.uk-card-body h4 {
  color: var(--text-color);
}
</style>
